import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button } from '../../../../../../../ui/button/button';
import { CustomSelect } from '../../../../../../../ui/custom_react_select/custom_select';
import inputStyles from '../../../../../../../ui/input/input.module.css';
import { PaginationNavigator } from '../../../../../../../ui/PaginationNavigator';
import TableSkeletonLoader from '../../../../../../../ui/TableSkeletonLoader';
import { SecondaryTypography } from '../../../../../../../ui/typography/typography';
import { getCandidateApplicants, sendInviteApplicants } from '../../../../../../../utils/api_service/JobOpenings';
import { FormattedSelectOptions } from '../../../../../../../utils/types/analytics/analytics_types';
import { AssessmentUser } from '../../../../../../../utils/types/assessment_types';
import { CandidateJobApplicantsData } from '../../../../../../../utils/types/JobOpenings';
import { SelectOptions } from '../../../../../../../utils/types/react_select_types';
import commonStyles
  from '../../../../../../assessment/view/assessment_list/assessment_list_table/assessment_list_table.module.css';
import {
  AssessmentUserRow,
} from '../../../../../../assessment/view/view_assessment/assessment_users/assessment_user_row/assessment_user_row';
import { CandidateRow } from './CandidateRow';

const applicantStatusType: SelectOptions[] = [
  {
    value: 'Outstanding',
    label: 'Outstanding',
  },
  {
    value: 'Suitable',
    label: 'Suitable',
  },
  {
    value: 'New',
    label: 'New',
  },
];

const assessmentStatusType: SelectOptions[] = [
  {
    value: 'Completed',
    label: 'Completed',
  },
  {
    value: 'Assigned',
    label: 'Assigned',
  },
  {
    value: 'Expired',
    label: 'Expired',
  },
  {
    value: 'In Progress',
    label: 'In Progress',
  },
  {
    value: 'Scoring',
    label: 'Scoring',
  },
];

export type CandidateListType = {
  enabled: boolean,
  base64Encoded?: string,
  firstName: string,
  lastName: string,
  email: string,
  id: string
  applicantStatus: string,
  invited: boolean,
  assessmentStatus: string
}

export const CandidateInvites = () => {
  const { id } = useParams<{ id: string }>();
  const [candidateInviteObject, setCandidateInviteObject] = useState<CandidateJobApplicantsData>({
    applicantStatus: [],
    assessmentStatus: [],
    email: '',
    name: '',
  });
  const [candidatesList, setCandidatesList] = useState<CandidateListType[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [selectedCandidates, setSelectedCandidates] = useState<string[]>([]);
  const [limit, setLimit] = useState<number>(10);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchCandidateApplicants = async () => {
    setLoading(true);
    try {
      const response = await getCandidateApplicants(id, candidateInviteObject, pageNumber, limit);
      const data = await response.json();

      setCandidatesList(data.applicants);
      setSelectedCandidates([]);
      setTotalPages(Math.ceil(data.total_count / limit));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error('Error Getting Job Applicants', { position: 'bottom-center' });
      setSelectedCandidates([]);
      setCandidatesList([]);
      setTotalPages(1);
    }
  };

  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      fetchCandidateApplicants();
    }, 1000);

    return () => clearTimeout(timer);
  }, [pageNumber, candidateInviteObject, limit]);

  const sendInvites = async () => {
    try {
      const response = await sendInviteApplicants(id, selectedCandidates);
      const data = await response.json();
      setLoading(true);
      await fetchCandidateApplicants();
      toast.success('Invitation Sent To Applicants', { position: 'bottom-center' });
    } catch (error) {
      setLoading(false);
      toast.error('Error Sending Invite Applicants', { position: 'bottom-center' });
      setTotalPages(1);
    }
  };

  const toggleRowSelection = (candidateId: string) => {
    if (selectedCandidates.includes(candidateId)) {
      setSelectedCandidates(selectedCandidates.filter((selectedRow) => selectedRow !== candidateId));
    } else {
      setSelectedCandidates([...selectedCandidates, candidateId]);
    }
  };

  const selectAllCandidates = () => {
    if (selectedCandidates.length === candidatesList.length) {
      setSelectedCandidates([]);
    } else {
      setSelectedCandidates(candidatesList.map(obj => obj.id));
    }
  };

  const handleStatusListChange = (label: string, selectedValue: SelectOptions[]) => {
    const values = selectedValue.map(item => item.value);
    setCandidateInviteObject(prevState => ({
      ...prevState,
      [label]: values,
    }));
  };

  return (
    <div className='flex-col'>
      <div className='flex w-full mt-10 gap-3'>
        <div className='w-1/5'>
          <input
              className={inputStyles.inputStyles}
              type='text'
              placeholder='Name'
              value={candidateInviteObject.name}
              onChange={(e) => setCandidateInviteObject({ ...candidateInviteObject, name: e.target.value })}

          />
        </div>
        <div className='w-1/5'>
          <input
              className={inputStyles.inputStyles}
              type='text'
              placeholder='Email'
              value={candidateInviteObject.email}
              onChange={(e) => setCandidateInviteObject({ ...candidateInviteObject, email: e.target.value })}
          />
        </div>
        <div className='w-1/5'>
          <CustomSelect
              placeholder='Applicant Status'
              name='level'
              value={candidateInviteObject.applicantStatus.map(item => ({ label: item, value: item }))}
              options={applicantStatusType}
              onChange={(option: SelectOptions[]) => handleStatusListChange('applicantStatus', option)}
              isMulti={true}
          />
        </div>
        <div className='w-1/5'>
          <CustomSelect
              placeholder='Assessment Status'
              name='level'
              value={candidateInviteObject.assessmentStatus.map(item => ({ label: item, value: item }))}
              options={assessmentStatusType}
              onChange={(option: SelectOptions[]) => handleStatusListChange('assessmentStatus', option)}
              isMulti={true}
          />
        </div>
        <div className='w-1/5'>
          <button
              className='w-full text-white font-semibold rounded-md px-5 py-2 mt-1 bg-primary-blue-500 hover:bg-primary-gradient'
              onClick={sendInvites}
          >
            Send Invite
          </button>
        </div>
      </div>
      { (candidatesList && candidatesList.length !== 0) ? (
        <div className='flex-col mt-5'>
          <div className='text-gray-500 text-sm font-semibold'>
            { selectedCandidates.length } Candidates Selected
          </div>
          <div className='flex-grow'>
            <table
                className={commonStyles.tableStyle}
            >
              <thead>
                <tr
                    className={commonStyles.tableHeader}
                >
                  <th>
                    <div className='flex justify-center gap-2'>
                      <input
                          type='checkbox'
                          checked={selectedCandidates.length === candidatesList.length}
                          onChange={() => selectAllCandidates()}
                      />
                      <SecondaryTypography.XSmall
                          alignment='center'
                          textCase='uppercase'
                          fontWeight='semi-bold'
                      >
                        Select All
                      </SecondaryTypography.XSmall>
                    </div>
                  </th>
                  <th>
                    <SecondaryTypography.XSmall
                        alignment='center'
                        textCase='uppercase'
                        fontWeight='semi-bold'
                    >
                      Candidate Id
                    </SecondaryTypography.XSmall>
                  </th>
                  <th>
                    <SecondaryTypography.XSmall
                        alignment='center'
                        textCase='uppercase'
                        fontWeight='semi-bold'
                    >
                      Name
                    </SecondaryTypography.XSmall>
                  </th>
                  <th>
                    <SecondaryTypography.XSmall
                        alignment='center'
                        textCase='uppercase'
                        fontWeight='semi-bold'
                    >
                      Email
                    </SecondaryTypography.XSmall>
                  </th>
                  <th>
                    <SecondaryTypography.XSmall
                        alignment='center'
                        textCase='uppercase'
                        fontWeight='semi-bold'
                    >
                      Applicant Status
                    </SecondaryTypography.XSmall>
                  </th>
                  <th>
                    <SecondaryTypography.XSmall
                        alignment='center'
                        textCase='uppercase'
                        fontWeight='semi-bold'
                    >
                      Invited
                    </SecondaryTypography.XSmall>
                  </th>
                  <th>
                    <SecondaryTypography.XSmall
                        alignment='center'
                        textCase='uppercase'
                        fontWeight='semi-bold'
                    >
                      Assessment Status
                    </SecondaryTypography.XSmall>
                  </th>
                </tr>
              </thead>
              <tbody>
                { (candidatesList.length > 0 && !loading) && candidatesList.map((user) => {
                  return (
                    <CandidateRow
                        key={user.id}
                        candidate={user}
                        selectCandidate={toggleRowSelection}
                        checked={selectedCandidates.includes(user.id)}
                    />
                  );
                }) }
              </tbody>
            </table>
            { loading && (
              <TableSkeletonLoader numRows={11} height={10} />
            ) }
          </div>
          <div className='ml-auto mr-auto mt-5'>
            <PaginationNavigator
                pageNumber={pageNumber}
                maxPageNumber={totalPages}
                setPageNumber={setPageNumber}
                limit={limit}
                setLimit={setLimit}
            />
          </div>
        </div>
      ) : (
        <div className='text-center py-10'>
          <div className='text-gray-800 py-1 text-lg'>
            No Applicants
          </div>
        </div>
      ) }
    </div>
  );
};
