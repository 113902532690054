import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import autoAssessmentIllustration from '../../../../assets/images/graphics/auto-assessment.svg';
import interviewManagementIllustration from '../../../../assets/images/graphics/Interview-management.svg';
import outstandingIllustration from '../../../../assets/images/graphics/oustanding-illustration.svg';
import resumeIllustration from '../../../../assets/images/graphics/resume-illustration.svg';
import suitabilityIllustration from '../../../../assets/images/graphics/suitability-illustration.svg';
import teamIllustration from '../../../../assets/images/graphics/team-illustration.svg';
import { Button } from '../../../../ui/button/button';
import { createJobOpening } from '../../../../utils/api_service/JobOpenings';
import { JobOpeningsContext } from '../../../../utils/contexts/JobOpening';
import { jobOpeningTypes } from '../../../../utils/types/JobOpenings';
import styles from '../../../assessment/create/modal_content/choose_assessment_type_modal.module.css';
import { SelectAssessmentType } from '../../../assessment/create/modal_content/select_assessment_type/select_assessment_type';

type ChooseRecruitmentTypeModalProps = {
  closeModal: () => void,
}
export const ChooseRecruitmentTypeModal = ({ closeModal }: ChooseRecruitmentTypeModalProps) => {
  const history = useHistory();
  const { setJobOpeningElements, setCompletedJobOpeningDetails } = useContext(JobOpeningsContext);
  const [selectedTypes, setSelectedTypes] = useState<jobOpeningTypes[]>(['Job Opening Details', 'Applicant Analysis']);
  const disabled = ['Job Posting', 'Interviews', 'Job Opening Details', 'Applicant Analysis'];
  const [loading, setLoading] = useState<boolean>(false);

  const setType = async () => {
    setLoading(true);
    try {
      const response = await createJobOpening(selectedTypes);
      const responseData = await response.json();

      if (response.ok) {
        history.push(`/job-openings/${responseData.id}`);
        setJobOpeningElements([]);
        setCompletedJobOpeningDetails([]);
        closeModal();
      } else {
        toast.error('Error Creating JobOpening', { position: 'bottom-center' });
      }
    } catch (error) {
      toast.error('Error Creating JobOpening', { position: 'bottom-center' });
    } finally {
      setLoading(false);
    }
  };

  function toggleSelectedType(element: jobOpeningTypes) {
    if (element && disabled.includes(element)) {
      return;
    }
    if (selectedTypes.includes(element)) {
      setSelectedTypes(selectedTypes.filter(t => t !== element));
    } else {
      setSelectedTypes([...selectedTypes, element]);
    }
  }

  return (
    <>
      <h3
          className={`${styles.modalHeader} pt-8`}
      >
        Job Opening Elements
      </h3>
      <div
          className={`${styles.selectTypeContainer} pt-6`}
      >
        <SelectAssessmentType
            image={teamIllustration}
            label='Job Posting'
            selected={selectedTypes.includes('Job Posting')}
            onClick={() => toggleSelectedType('Job Posting')}
            disabled={true}
        />
        <SelectAssessmentType
            image={resumeIllustration}
            label='Applicant Analysis'
            selected={selectedTypes.includes('Applicant Analysis')}
            onClick={() => toggleSelectedType('Applicant Analysis')}
        />
        <SelectAssessmentType
            image={suitabilityIllustration}
            label='Auto-Suitability'
            selected={selectedTypes.includes('Auto-Suitability')}
            onClick={() => toggleSelectedType('Auto-Suitability')}
        />
        <SelectAssessmentType
            image={outstandingIllustration}
            label='Auto-Outstanding'
            selected={selectedTypes.includes('Auto-Outstanding')}
            onClick={() => toggleSelectedType('Auto-Outstanding')}
        />
        <SelectAssessmentType
            image={autoAssessmentIllustration}
            label='Auto-Assessments'
            selected={selectedTypes.includes('Auto-Assessments')}
            onClick={() => toggleSelectedType('Auto-Assessments')}
        />
        <SelectAssessmentType
            image={interviewManagementIllustration}
            label='Interviews'
            selected={selectedTypes.includes('Interviews')}
            onClick={() => toggleSelectedType('Interviews')}
            disabled={true}
        />
      </div>
      { loading ? (
        <Button
            onClick={setType}
            variant='primary'
            size='medium'
            disabled={true}
            customClass={styles.buttonCustom}
        >
          Loading...
        </Button>
      ): (
        <Button
            onClick={setType}
            variant='primary'
            size='medium'
            disabled={selectedTypes.length === 0}
            customClass={styles.buttonCustom}
        >
          Next
        </Button>
      ) }
    </>
  );
};
