import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RoleIcon, UserIcon, Users } from '../../../ui/icons/icons';
import { PrimaryTypography, SecondaryTypography } from '../../../ui/typography/typography';
import { fetchOrganizationUsers } from '../../../utils/redux_store/features/organization_user_reducer';
import { fetchUserGroups } from '../../../utils/redux_store/features/user_group_reducer';
import { fetchUserRoles } from '../../../utils/redux_store/features/user_role_reducer';
import { RootState, store } from '../../../utils/redux_store/store';
import { Tabs } from '../../assessment/view/tabs/tabs';
import commonStyles from  '../admin.module.css';
import styles from './access_management.module.css';
import { GroupManagement } from './group_management/group_management';
import { RoleManagement } from './role_management/role_management';
import { UserManagement } from './user_management/user_management';

export const AccessManagement = () => {
  const [activeTab, setActiveTab] = useState<number>(0);
  // const tabTitles = ['Users', 'Groups', 'Roles'];
  const tabTitles = ['Groups', 'Roles'];
  const userCount = useSelector((state: RootState) => state.organizationUsers.organizationUserList.length);
  const groupCount = useSelector((state: RootState) => state.userGroups.userGroupList.length);
  const roleCount = useSelector((state: RootState) => state.userRoles.userRoleList.length);

  useEffect(() => {
    store.dispatch(fetchOrganizationUsers());
    store.dispatch(fetchUserGroups());
    store.dispatch(fetchUserRoles());
  }, []);

  const renderTabContent = () => {
    // if (activeTab === 0) {
    //   return <UserManagement/>;
    // } else if (activeTab === 1)  {
    //   return <GroupManagement/>;
    // } else if (activeTab === 2) {
    //   return <RoleManagement/>;
    // }

    if (activeTab === 0) {
      return <GroupManagement/>;
    } else if (activeTab === 1)  {
      return <RoleManagement/>;
    }
  };

  return (
    <div
        className={commonStyles.container}
    >
      <PrimaryTypography.FiveXLarge
          fontWeight='bold'
          keepDefaultMargins={true}
      >
        Admin
      </PrimaryTypography.FiveXLarge>
      <div
          className={commonStyles.contentContainer}
      >
        <PrimaryTypography.FiveXLarge
            fontWeight='bold'
            keepDefaultMargins={true}
            textCase='capitalize'
        >
          Users/Groups
        </PrimaryTypography.FiveXLarge>
        <div
            className={classNames(styles.flexRowContainer, styles.marginIconGroup, styles.rowGap)}
        >
          <div
              className={classNames(styles.flexRowContainer, styles.iconGap)}
          >
            <div
                className={styles.icons}
            >
              <UserIcon/>
            </div>
            <SecondaryTypography.XSmall
                fontWeight='semi-bold'
                textCase='capitalize'
            >
              { userCount } Users
            </SecondaryTypography.XSmall>
          </div>
          <div
              className={classNames(styles.flexRowContainer, styles.iconGap)}
          >
            <div
                className={styles.icons}
            >
              <Users/>
            </div>
            <SecondaryTypography.XSmall
                fontWeight='semi-bold'
                textCase='capitalize'
            >
              { groupCount } Groups
            </SecondaryTypography.XSmall>
          </div>
          <div
              className={classNames(styles.flexRowContainer, styles.iconGap)}
          >
            <div
                className={styles.icons}
            >
              <RoleIcon/>
            </div>
            <SecondaryTypography.XSmall
                fontWeight='semi-bold'
                textCase='capitalize'
            >
              { roleCount } Roles
            </SecondaryTypography.XSmall>
          </div>
        </div>
        <Tabs
            tabTitles={tabTitles}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
        />
        { renderTabContent() }
      </div>
    </div>
  );
};
