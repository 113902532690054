import React, { Dispatch, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button } from '../../../../../ui/button/button';
import AsyncCustomSelect from '../../../../../ui/custom_react_select/async_select';
import { CustomSelect } from '../../../../../ui/custom_react_select/custom_select';
import { CollapseAll, ExpandAll } from '../../../../../ui/icons/icons';
import { TextInput } from '../../../../../ui/input/text_input/text_input';
import TopBanner from '../../../../../ui/TopBanner';
import {
  createJobDescription,
  getJobDescription,
  getUniqueIdJobDescription,
} from '../../../../../utils/api_service/JobOpenings';
import useDebouncedJobTitleSearch from '../../../../../utils/helpers/AsyncSelect/JobTitleGet';
import { JobTitle } from '../../../../../utils/types/admin_types';
import { JobDescription, JobReferenceNumberTypes, showAllTypes, workType } from '../../../../../utils/types/JobOpenings';
import { SelectOptions } from '../../../../../utils/types/react_select_types';
import LoadingSpinner from '../../ui/LoaderSpinner';
import DropdownWithModal, { ModalOption } from '../../ui/SelectModalOption';
import ToggleButtonGroup from '../../ui/ToggleButtonGroup';
import ListFields from './ListFieldComponents';
import ImportJobDescription from './ModalContent';

const  workTypes: SelectOptions[] = [{
  label: 'Permanent',
  value: 'Permanent',
}, {
  label: 'Part Time',
  value: 'Part Time',
}, {
  label: 'Casual',
  value: 'Casual',
}];

type JobDescriptionTabProps = {
  jobDescription: JobDescription,
  setJobDescription: Dispatch<React.SetStateAction<JobDescription>>,
  jobTitle: SelectOptions | undefined,
  setJobTitle: React.Dispatch<React.SetStateAction<SelectOptions | undefined>>,
  jobTitleList: JobTitle[],
  completedElement: () => void,
  editable: boolean,
}

const JobDescriptionTab = ( { jobDescription, setJobDescription, jobTitle, setJobTitle, jobTitleList, completedElement, editable }: JobDescriptionTabProps) => {
  const [showAll, setShowAll] = useState<showAllTypes>('showAll');
  const { loadOptionsDebounced } = useDebouncedJobTitleSearch();
  const [loadingJob, setLoadingJob] = useState<boolean>(() => {
    const loadingJob = (
      (jobDescription.filename && jobDescription.filename !== '' && !jobDescription.completed) ||
      (jobDescription.transcript && jobDescription.transcript !== '' && !jobDescription.completed)
    ) as boolean;
    return (loadingJob);
  });
  const [loading, setLoading] = useState<boolean>(false);

  const options: ModalOption[] = [
    {
      value: 'importJobDescription',
      label: 'Import Job Description',
      modalContent: (closeModal) => <ImportJobDescription closeModal={closeModal} setLoading={setLoadingJob}/>,
    },
    {
      value: 'createJobTemplate',
      label: 'Create Job Template',
      modalContent: <></>,
      disabled: true,
    },
  ];

  const defaultJobTitles = jobTitleList.map((jobTitle) => ({
    value: jobTitle.id,
    label: jobTitle.jobTitle,
  }));

  useEffect(() => {
    const fetchUniqueId = async () => {
      try {
        const response = await getUniqueIdJobDescription();
        const data = await response.json();
        setJobDescription(prevState => ({
          ...prevState,
          jobReferenceNumberAuto: data.id,
        }));
      } catch (error) {
        toast.error('Error Fetching Unique Id', { position: 'bottom-center' });
      }
    };
    if (jobDescription.jobReferenceNumberAuto === null || jobDescription.jobReferenceNumberAuto === '') {
      fetchUniqueId();
    }
  }, []);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    let pollInterval: NodeJS.Timeout;
    if (loadingJob) {
      pollInterval = setInterval(() => {
        getJobDescription(jobDescription.jobOpeningId)
          .then(response => {
            return response.json();
          })
          .then((data: JobDescription) => {
            setJobDescription({
              ...data,
              jobTitle: typeof data.jobTitle !== 'string' ? data.jobTitle?.id : data.jobTitle,
            });
            if (data.completed || data.status === 'Error') {
              setLoadingJob(false);
              clearInterval(pollInterval);
              if (data.status === 'Error') {
                toast.error('Error Processing Job Description, Retry Again', { position: 'bottom-center' });
              } else {
                let jobTitleValue = data.jobTitle;
                if (jobTitleValue) {
                  const jobTitleOption: SelectOptions = {
                    label: typeof jobTitleValue !== 'string' ? jobTitleValue?.jobTitle : jobTitleValue,
                    value: typeof jobTitleValue !== 'string' ? jobTitleValue?.id : jobTitleValue,
                  };
                  setJobTitle(jobTitleOption);
                }
              }
            }
          })
          .catch(() => {
            toast.error('Error Fetching Job Description', { position: 'bottom-center' });
            setLoadingJob(false);
            clearInterval(pollInterval);
          });
      }, 3000);
    }
    return () => clearInterval(pollInterval);
  }, [loadingJob]);

  const toggleShowAll = () => {
    let newShowAllState: showAllTypes = 'showAll';
    if (showAll === 'showAll') {
      newShowAllState = 'collapseAll';
    } else if (showAll === 'collapseAll') {
      newShowAllState = 'showAll';
    } else {
      newShowAllState = 'showAll';
    }
    setShowAll(newShowAllState);
  };

  const handleChange = (selectedOption: SelectOptions) => {
    const workType: workType = selectedOption.value as workType;
    setJobDescription({ ...jobDescription, workType: workType });
  };

  const handleJobTitleChange = (selectedOptions: SelectOptions | null) => {
    if (selectedOptions === null) {
      setJobTitle(undefined);
      setJobDescription({ ...jobDescription, jobTitle: undefined });
    } else {
      setJobTitle(selectedOptions);
      setJobDescription({ ...jobDescription, jobTitle: selectedOptions.value });
    }
  };

  const validateJobDescription = () => {
    const { jobTitle, jobReferenceNumber, jobLocation, workType, minimumWorkExperience } = jobDescription;

    if (!jobTitle) {
      toast.error('Job Title is a required field', { position: 'bottom-center' });
      return false;
    }

    if (!jobReferenceNumber) {
      toast.error('Job Reference Number is a required field', { position: 'bottom-center' });
      return false;
    }

    if (!jobLocation) {
      toast.error('Job Location is a required field', { position: 'bottom-center' });
      return false;
    }

    if (!workType) {
      toast.error('Work Type is a required field', { position: 'bottom-center' });
      return false;
    }

    if (!minimumWorkExperience) {
      toast.error('Minimum Work Experience is a required field', { position: 'bottom-center' });
      return false;
    }

    return true;
  };

  const submitJobDescription = async () => {
    const fieldsValid = validateJobDescription();
    if (!fieldsValid) {
      return;
    }
    setLoading(true);
    try {
      const response = await createJobDescription(jobDescription);
      if (response.ok) {
        const data : JobDescription = await response.json();
        setJobDescription({
          ...jobDescription,
          id: data.id,
        });
        toast.success('Job Description Saved Successfully', { position: 'bottom-center' });
        completedElement();
      } else {
        toast.error('Error Submitting Job Description', { position: 'bottom-center' });
      }
    } catch {
      toast.error('Error Submitting Job Description', { position: 'bottom-center' });
    } finally {
      setLoading(false);
    }
  };

  const changeJobReferenceType = (value: JobReferenceNumberTypes) => {
    if (value === 'Manual') {
      setJobDescription({
        ...jobDescription,
        jobReferenceNumber: '',
        jobReferenceType: value,
      });
    } else if (value === 'Auto') {
      setJobDescription({
        ...jobDescription,
        jobReferenceNumber: jobDescription.jobReferenceNumberAuto || '',
        jobReferenceType: value,
      });
    } else {
      setJobDescription({
        ...jobDescription,
        jobReferenceNumber: jobDescription.jobReferenceNumberJD || '',
        jobReferenceType: value,
      });
    }
  };

  return (
    <>
      { loadingJob ? (
        <LoadingSpinner label={jobDescription.status}/>
      ) : (
        <div className='relative flex flex-col gap-4 h-full'>
          { !editable && (
            <TopBanner
                title='This page is not editable'
                message='CV has been uploaded therefore these values are now not editable.'
                type='normal'/>
          ) }
          <div className='flex flex-row justify-between'>
            <div className=''>
              <div className='text-gray-800 py-1 text-sm pl-12'>
                Job Reference Number
              </div>
              <div className='flex gap-4 items-center w-full'>
                <div>
                  <button
                      onClick={() => toggleShowAll()}
                      className='w-7 h-7'
                  >
                    { showAll === 'collapseAll' || showAll === 'random' ? <ExpandAll/> : <CollapseAll/> }
                  </button>
                </div>
                <div className='w-1/3'>
                  <TextInput
                      name='title'
                      value={jobDescription.jobReferenceNumber || ''}
                      type='text'
                      onChange={(e) => setJobDescription({ ...jobDescription, jobReferenceNumber: e.target.value })}
                      disabled={jobDescription.jobReferenceType === 'Auto' || jobDescription.jobReferenceType === 'JD' || !editable}
                  />
                </div>
                <div>
                  <ToggleButtonGroup
                      buttons={['Auto', 'Manual', 'JD']}
                      initialActive={jobDescription.jobReferenceType}
                      onActiveChange={(value) => (changeJobReferenceType(value as JobReferenceNumberTypes))}
                      disabled={!editable}
                  />
                </div>
              </div>
            </div>
            <div className=''>
              <DropdownWithModal options={options} disabled={!editable}/>
            </div>
          </div>

          <div className='flex gap-4 pl-12'>
            <div className='w-1/3'>
              <div className='text-gray-800 py-1 text-sm'>
                Job Title
              </div>
              <AsyncCustomSelect
                  value={jobTitle}
                  defaultOptions={defaultJobTitles}
                  onChange={handleJobTitleChange}
                  loadOptions={(inputValue, callback) => loadOptionsDebounced(inputValue, callback)}
                  isMulti={false}
                  disabled={!editable}
              />
            </div>
            <div className='w-1/3'>
              <div className='text-gray-800 py-1 text-sm'>
                Job Location
              </div>
              <TextInput
                  name='jobLocation'
                  value={jobDescription.jobLocation as string}
                  type='text'
                  onChange={(e) => setJobDescription({ ...jobDescription, jobLocation: e.target.value })}
                  disabled={!editable}
              />
            </div>
          </div>
          <div className='flex gap-4 pl-12'>
            <div className='w-1/3'>
              <div className='text-gray-800 py-1 text-sm'>
                Work Type
              </div>
              <CustomSelect
                  name='jobRequisitionType'
                  value={{
                    label: jobDescription.workType ? jobDescription.workType : 'Select Work Type',
                    value: jobDescription.workType ? jobDescription.workType : 'Select Work Type',
                  }}
                  options={workTypes}
                  onChange={handleChange}
                  disabled={!editable}
              />
            </div>
            <div className='w-1/3'>
              <div className='text-gray-800 py-1 text-sm'>
                Minimum Work Experience
              </div>
              <TextInput
                  name='minimumWorkExperience'
                  value={jobDescription.minimumWorkExperience ? String(jobDescription.minimumWorkExperience) : '0'}
                  type='number'
                  onChange={(e) => setJobDescription({
                    ...jobDescription,
                    minimumWorkExperience: parseInt(e.target.value),
                  })}
                  disabled={!editable}
              />
            </div>
          </div>
          <div className='mb-16'>
            <ListFields
                showAll={showAll}
                setShowAll={setShowAll}
                jobDescription={jobDescription}
                disabled={!editable}
                setJobDescription={setJobDescription}
            />
          </div>
          <div className='absolute bottom-0 right-0'>
            <Button
                onClick={() => submitJobDescription()}
                variant='primary'
                size='medium'
                disabled={loading || !editable || jobDescription.minimumWorkExperience === undefined || jobDescription.jobTitle === '' || jobDescription.jobLocation === '' || jobDescription.jobReferenceNumber === '' || (jobDescription.workType !== 'Permanent' &&
                jobDescription.workType !== 'Part Time' && jobDescription.workType !== 'Casual')}
            >
              Save Job Description
            </Button>
          </div>
        </div>
      ) }
    </>
  );
};

export default JobDescriptionTab;
