import React from 'react';
import { ChevronLeft, ChevronRight } from '../icons/icons';

type PaginationControllerProps = {
  pageNumber: number,
  maxPageNumber: number,
  setPageNumber: (page: number) => void,
  limit?: number,
  setLimit?: (limit: number) => void,
}

export const PaginationNavigator = ({
  pageNumber,
  maxPageNumber,
  setPageNumber,
  limit,
  setLimit,
}: PaginationControllerProps) => {
  const handlePrev = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const handleNext = () => {
    if (pageNumber < maxPageNumber) {
      setPageNumber(pageNumber + 1);
    }
  };

  const renderPages = () => {
    const pages = [];
    const totalPages = Math.min(maxPageNumber, 5);

    let startPage = Math.max(1, pageNumber - Math.floor(totalPages / 2));
    let endPage = startPage + totalPages - 1;

    if (endPage > maxPageNumber) {
      startPage -= endPage - maxPageNumber;
      endPage = maxPageNumber;
    }

    if (startPage > 1) {
      pages.push(
        <span key={1} className={'cursor-pointer px-4 py-2 border rounded hover:bg-gray-200'} onClick={() => setPageNumber(1)}>1</span>,
      );
      pages.push(<span key='ellipsis1' className={'px-4 py-2'}>....</span>);
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <span key={i} className={`cursor-pointer px-4 py-2 border rounded hover:bg-gray-200 ${pageNumber === i ? 'bg-gray-200' : ''}`} onClick={() => setPageNumber(i)}>{ i }</span>,
      );
    }

    if (endPage < maxPageNumber) {
      pages.push(<span key='ellipsis2' className={'px-2 py-1'}>....</span>);
      pages.push(
        <span key={maxPageNumber} className={`cursor-pointer px-4 py-2 border rounded hover:bg-gray-200 ${pageNumber === maxPageNumber ? 'bg-gray-200' : ''}`} onClick={() => setPageNumber(maxPageNumber)}>{ maxPageNumber }</span>,
      );
    }

    return pages;
  };

  const handleLimitChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (setLimit) {
      setLimit(Number(event.target.value));
    }
  };

  return (
    <div className='relative flex items-center w-full justify-center text-lg'>
      <div onClick={handlePrev}>
        <ChevronLeft className={`cursor-pointer h-7 w-7 mr-4 ${pageNumber === 1 ? 'text-gray-400' : 'text-gray-800'}`}/>
      </div>
      { renderPages() }
      <div onClick={handleNext}>
        <ChevronRight
            className={`cursor-pointer h-7 w-7 ml-4 ${pageNumber === maxPageNumber ? 'text-gray-400' : 'text-gray-800'}`}/>
      </div>
      <div className='absolute bottom-0 right-0'>
        { !!limit && setLimit && (
          <select
              className='px-3 py-2 border rounded'
              value={limit}
              onChange={handleLimitChange}
          >
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
            <option value={200}>200</option>
          </select>
        ) }
      </div>

    </div>
  );
};
