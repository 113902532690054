import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Refresh } from '../../../../../ui/icons/icons';
import { UploadFileComponent } from '../../../../../ui/input/FileInput';
import { Modal } from '../../../../../ui/modal/modal';
import { PaginationNavigator } from '../../../../../ui/PaginationNavigator';
import TopBanner from '../../../../../ui/TopBanner';
import { apiUrl } from '../../../../../utils/api_service/api_url';
import { getJobApplicants } from '../../../../../utils/api_service/JobOpenings';
import { JobOpeningsContext } from '../../../../../utils/contexts/JobOpening';
import { useUploadForm } from '../../../../../utils/helpers/api_utils';
import { JobApplicant } from '../../../../../utils/types/JobOpenings';
import ApplicantTable from './ApplicantTable';

const fileTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];

type ApplicantStatus = {
  Completed: number,
  InProgress: number,
  Error: number,
}

type CVIngestionProps = {
  completedElement: () => void,
}

const CVIngestionTab = ({ completedElement }: CVIngestionProps) => {
  const { id } = useParams<{ id: string }>();
  const { completedJobOpeningDetails } = useContext(JobOpeningsContext);
  const uploadURL = apiUrl + `/job-openings/${id}/cv-ingestion/file`;
  const { uploadStatus, uploadForm, progress } = useUploadForm(uploadURL);
  const [uploadedFile, setUploadedFile] = useState<File[] | null>(null);
  const [modalState, setModalState] = useState<boolean>(false);
  const [jobApplicants, setJobApplicants] = useState<JobApplicant[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [ ApplicantStatus, setApplicantStatus ] = useState<ApplicantStatus>({
    Completed: 0,
    InProgress: 0,
    Error: 0,
  });
  const [loading, setLoading] = useState<boolean>(false);

  const handleModalToggle = () => {
    setModalState(false);
  };

  useEffect(() => {
    fetchJobApplicants();
  }, [id, pageNumber]);

  const fetchJobApplicants = () => {
    setLoading(true);
    getJobApplicants(id, '', pageNumber, 10, '').then(response => {
      if (!response.ok) {
        throw new Error();
      }
      return response.json();
    }).then((data) => {
      setJobApplicants(data.jobApplicants);
      setTotalPages(Math.ceil(data.totalApplicants / 10));
      setApplicantStatus({
        Completed: data.completedApplicants,
        InProgress: data.inProgressApplicants,
        Error: data.errorApplicants,
      });
    })
      .catch(() => {
        toast.error('Error Getting Job Applicants', { position: 'bottom-center' });
        setTotalPages(1);
        setJobApplicants([]);
      }).finally(() => setLoading(false));
  };

  const uploadFiles = async () => {
    try {
      if (uploadedFile) {
        const formData = new FormData();
        uploadedFile.forEach(file => {
          formData.append('files', file);
        });
        await uploadForm(formData);
        toast.success('Successfully Uploaded Files', { position: 'bottom-center' });
        completedElement();
        setUploadedFile(null);
        setModalState(false);
        fetchJobApplicants();
      }
    } catch {
      toast.error('Failed Uploading Files', { position: 'bottom-center' });
    }
  };

  const refreshApplicants = () => {
    setPageNumber(1);
    fetchJobApplicants();
  };

  return (
    <div className='flex flex-col gap-4 h-full'>
      { !completedJobOpeningDetails.includes('CV Ingestion') && (
        <TopBanner
            title='Final Check: Confirm Before Uploading'
            message='Once you upload the first applicant, all configurations tabs will be disabled, and their values will be locked. Please ensure all information is accurate before proceeding.'
            type='warning'/>
      ) }
      <div className='flex text-base gap-5 justify-between items-center'>
        <div className='flex gap-5'>
          <div>
            <span className='text-green-800'>Completed { ApplicantStatus.Completed } </span>
          </div>
          <div>
            <span className='text-blue-800'>In Progress { ApplicantStatus.InProgress } </span>
          </div>
          <div>
            <span className='text-red-800'>Error { ApplicantStatus.Error } </span>
          </div>
        </div>
        <div className='flex gap-4'>
          <button
              className='group rounded-md  px-2 bg-white border border-primary-blue-500 hover:bg-primary-blue-500'
              onClick={() => refreshApplicants()}
          >
            <Refresh className='h-6 w-6 stroke-primary-blue-500 fill-none group-hover:stroke-white hover:fill-none'/>
          </button>
          <button
              className='w-full text-white font-semibold rounded-md px-5 py-2 bg-primary-blue-500 hover:bg-primary-gradient'
              onClick={() => setModalState(true)}
          >
            Upload Resume
          </button>
        </div>
      </div>
      <div className='overflow-auto'>
        <ApplicantTable tableData={jobApplicants} loading={loading}/>
      </div>
      <Modal
          visible={modalState}
          modalContent={
            <div className='p-5'>
              <UploadFileComponent
                  uploadedFile={uploadedFile}
                  setUploadedFile={setUploadedFile}
                  validTypes={fileTypes}
                  isMultiple={true}
              />
              <div className='mt-4'>
                <button
                    className={`w-full text-white font-semibold rounded-md py-2 ${ uploadStatus !== 'loading' ? 'bg-primary-blue-500 hover:bg-primary-gradient' : 'bg-gray-300 cursor-default'}`}
                    onClick={uploadFiles}
                    disabled={uploadStatus === 'loading'}
                >
                  Upload
                </button>
                { uploadStatus === 'loading' && (
                  <div className='my-5 h-4 rounded-md bg-gray-200'>
                    <div className='h-4 rounded-md bg-primary-gradient' style={{ width: `${progress}%` }}></div>
                  </div>
                ) }
              </div>
            </div>
          }
          toggleable={true}
          toggle={handleModalToggle}
      />
      { ( jobApplicants.length > 0 && !loading) && (
        <div className='ml-auto mr-auto mt-5'>
          <PaginationNavigator
              pageNumber={pageNumber}
              maxPageNumber={totalPages}
              setPageNumber={setPageNumber}
          />
        </div>
      ) }
    </div>
  );
};

export default CVIngestionTab;
