import React from 'react';
import { SecondaryTypography } from '../../../../../../../../ui/typography/typography';
import styles from '../../../../../../../assessment/view/view_assessment/assessment_users/assessment_user_row/assessment_user_row.module.css';
import { CandidateListType } from '../index';

type CandidateRowProps = {
  candidate: CandidateListType,
  checked: boolean,
  selectCandidate: (candidateId: string) => void
}

export const CandidateRow = ({ candidate, checked, selectCandidate }: CandidateRowProps) => {

  const getTextColor = (status: string) => {
    switch (status) {
      case 'Completed':
        return 'text-lime-500';
      case 'In Progress':
        return 'text-yellow-500';
      case 'Scoring':
        return 'text-violet-600';
      case 'Expired':
        return 'text-rose-600';
      case 'Assigned':
        return 'text-sky-500';
      default:
        return '';
    }
  };

  return (
    <tr
        className={styles.tableRow}
    >
      <td>
        <SecondaryTypography.XSmall
            alignment='center'
        >
          <input
              type='checkbox'
              checked={checked}
              onChange={() => selectCandidate(candidate.id)}
          />
        </SecondaryTypography.XSmall>
      </td>
      <td>
        <SecondaryTypography.XSmall
            alignment='center'
        >
          { candidate.id }
        </SecondaryTypography.XSmall>
      </td>
      <td>
        <SecondaryTypography.XSmall
            alignment='center'
        >
          { candidate.firstName + ' ' + candidate.lastName }
        </SecondaryTypography.XSmall>
      </td>
      <td>
        <SecondaryTypography.XSmall
            alignment='center'
        >
          { candidate.email }
        </SecondaryTypography.XSmall>
      </td>
      <td>
        <SecondaryTypography.XSmall
            alignment='center'
            textCase='capitalize'
            fontWeight='bold'
        >
          { candidate.applicantStatus }
        </SecondaryTypography.XSmall>
      </td>
      <td>
        <div className={`${ candidate.invited ? 'text-green-400' : 'text-red-400'} font-semibold text-xl`}>
          { candidate.invited ? '✓' : 'x' }
        </div>
      </td>
      <td>
        <SecondaryTypography.XSmall
            alignment='center'
            textCase='capitalize'
            fontWeight='bold'
            className={getTextColor(candidate.assessmentStatus)}
        >
          { candidate.assessmentStatus }
        </SecondaryTypography.XSmall>
      </td>
    </tr>
  );
};
